import styled from 'styled-components'

const MapWrapper = styled.div`
  height: 10rem;
  width: 100%;

  .fullscreen:-webkit-full-screen {
    height: 100%;
    margin: 0;
  }

  .fullscreen:-ms-fullscreen {
    height: 100%;
  }

  .fullscreen:fullscreen {
    height: 100%;
  }

  .fullscreen {
    margin-bottom: 10px;
    width: 100%;
    height: 400px;
  }

  .ol-full-screen-false {
    zoom: 2;
  }

  .ol-full-screen-true {
    zoom: 2;
  }

  .ol-zoom-in {
    zoom: 2;
  }

  .ol-zoom-out {
    zoom: 2;
  }
`

export default MapWrapper
