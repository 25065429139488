import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { AxiosError, AxiosRequestConfig } from 'axios'

import { client } from 'src/API'
import { selectDefaultAccount } from 'src/modules/Users/slice'
import { LocalStorageItem, getItem } from 'src/utils/localStorage'

interface AxiosQuery {
  additionalBaseQueryHeaders?: { [key: string]: any }
  data?: AxiosRequestConfig['data']
  method?: AxiosRequestConfig['method']
  overrideBaseUrl?: string
  params?: AxiosRequestConfig['params']
  url: string
}

const axiosBaseQuery =
  (
    {
      baseUrl,
      skipAccount,
      additionalBaseQueryHeaders
    }: {
      baseUrl: string
      skipAccount?: boolean
      additionalBaseQueryHeaders?: { [key: string]: any }
    } = {
      baseUrl: '',
      skipAccount: false,
      additionalBaseQueryHeaders: {}
    }
  ): BaseQueryFn<
    AxiosQuery,
    unknown,
    {
      data?: string | {[key: string]: any},
      status?: number 
    }> =>
  async (
    { data, method = 'get', overrideBaseUrl = baseUrl, params, url },
    api
  ) => {
    const state = api.getState()
    const defaultAccount = selectDefaultAccount(state)
    if (!defaultAccount && !skipAccount) {
      return {
        error: {
          data: 'No Account ID Found',
          status: 400,
          statusText: 'Bad Request'
        }
      }
    }

    const accountUrl = `${overrideBaseUrl}${
      skipAccount ? '' : defaultAccount?.id
    }`
    try {
      const fullUrl = accountUrl + url
      const token = getItem(LocalStorageItem.TOKEN)
      const result = await client.request({
        data,
        headers: {
          Authorization: `Bearer ${token}`,
          ...additionalBaseQueryHeaders
        },
        method,
        params,
        url: fullUrl
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          data: err.response?.data || err.message,
          status: err.response?.status
        }
      }
    }
  }

export default axiosBaseQuery
