import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { PaginatedResponseV3, buildParameters } from 'src/services'
import axiosBaseQuery from './axiosBaseQuery'
import { Tags, SearchParams } from './types'
import {
  CommunicationV2,
  CommunicationInstanceV2,
  CommunicationTemplate,
  CommunicationBatch,
  CommunicationInstanceEvent
} from 'src/types/CommunicationsV2'

interface GetAllInstancesParams {
  searchParams: SearchParams
}

interface GetCommunicationsParams {
  searchParams: SearchParams
}

interface GetCommunicationParams {
  communicationId: string
}

interface GetInstanceParams {
  instanceId: string
}

interface GetBatchParams {
  searchParams: SearchParams
}

interface GetAllCommunicationsParams {
  searchParams: SearchParams
  hideInventoryChoices?: boolean
}

interface GetTemplateParams {
  templateId: string
}

interface TemplatesResponse {
  data: CommunicationTemplate[]
}

export interface CommunicationFormData {
  communicationType: string
  content: Record<string, unknown>
  eventId?: number
  name?: string
  status: string
  templateId: string
  triggerCondition?: string
  triggerType?: string
}

interface CreateCommunicationParams {
  communication: CommunicationFormData
}

interface UpdateCommunicationParams {
  communication: CommunicationV2
}

interface CreateBulkCommunicationInstanceExportParams {
  searchParams: SearchParams
  instanceIds: string[]
}

interface CancelBatchParams {
  batch: CommunicationBatch
}

export const communicationsTwoApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: `/platform/communications`,
    additionalBaseQueryHeaders: { 'Accept-version': 'v2' },
    skipAccount: true
  }),
  reducerPath: 'communicationsTwoApi',
  tagTypes: [
    Tags.Communications,
    Tags.CommunicationBatches,
    Tags.CommunicationInstances,
    Tags.CommunicationInstanceEvents,
    Tags.CommunicationTemplates
  ],
  endpoints: builder => ({
    getCommunications: builder.query<
      PaginatedResponseV3<CommunicationV2>,
      GetCommunicationsParams
    >({
      providesTags: [Tags.Communications],
      query: ({ searchParams }) => {
        const params = buildParameters(searchParams, true).toString()

        return {
          method: 'get',
          url: `/communication/instanceCounts/?${params}`
        }
      }
    }),
    getCommunication: builder.query<CommunicationV2, GetCommunicationParams>({
      providesTags: [Tags.Communications],
      query: ({ communicationId }) => {
        return {
          method: 'get',
          url: `/communication/${communicationId}`
        }
      }
    }),
    getTemplate: builder.query<CommunicationTemplate, GetTemplateParams>({
      providesTags: [Tags.CommunicationTemplates],
      query: ({ templateId }) => {
        return {
          method: 'get',
          url: `/template/${templateId}`
        }
      }
    }),
    getBatches: builder.query<
      PaginatedResponseV3<CommunicationBatch>,
      GetBatchParams
    >({
      providesTags: [Tags.CommunicationBatches],
      query: ({ searchParams }) => {
        const params = buildParameters(searchParams, true).toString()

        return {
          method: 'get',
          url: `/batch/?${params}`
        }
      },
      keepUnusedDataFor: 0
    }),
    getInstances: builder.query<
      PaginatedResponseV3<CommunicationInstanceV2>,
      GetAllInstancesParams
    >({
      providesTags: [Tags.CommunicationInstances],
      query: ({ searchParams }) => {
        const params = buildParameters(searchParams, true).toString()
        return {
          method: 'get',
          url: `/instance/?${params}`
        }
      },
      keepUnusedDataFor: 0
    }),
    getMostRecentInstanceEvent: builder.query<CommunicationInstanceEvent, GetInstanceParams>({
      providesTags: [Tags.CommunicationInstanceEvents],
      query: ({ instanceId }) => {
        return {
          method: 'get',
          url: `/instance/${instanceId}/eventDetails`
        }
      },
      keepUnusedDataFor: 0
    }),
    getAllCommunications: builder.query<
      PaginatedResponseV3<CommunicationV2>,
      GetAllCommunicationsParams
    >({
      providesTags: [Tags.Communications],
      query: ({ searchParams, hideInventoryChoices }) => {
        let params = buildParameters(searchParams, true).toString()

        if (hideInventoryChoices) {
          params = `hideInventoryChoices=${hideInventoryChoices}&${params}`
        }

        return {
          method: 'get',
          url: `/communication/?${params}`
        }
      }
    }),
    getAllCommunicationTemplates: builder.query<TemplatesResponse, void>({
      providesTags: [Tags.CommunicationTemplates],
      query: () => {
        return {
          method: 'GET',
          url: `/template`
        }
      }
    }),
    createCommunication: builder.mutation<
      CommunicationV2,
      CreateCommunicationParams
    >({
      invalidatesTags: [Tags.Communications],
      query: ({ communication }) => {
        return {
          data: communication,
          method: 'POST',
          url: `/communication/`
        }
      }
    }),
    updateCommunication: builder.mutation<
      CommunicationV2,
      UpdateCommunicationParams
    >({
      invalidatesTags: [Tags.Communications],
      query: ({ communication }) => {
        return {
          data: communication,
          method: 'PUT',
          url: `/communication/${communication.id}`
        }
      }
    }),
    createBatchCommunicationInstanceExport: builder.mutation<void, CreateBulkCommunicationInstanceExportParams>({
      query: ({ searchParams, instanceIds }) => {
        const paramBuilder = buildParameters(searchParams, true)
        for (const id of instanceIds) {
          paramBuilder.append('instanceIds', id)
        }
        const params = paramBuilder.toString()
        return {
          method: 'POST',
          url: `/batch/zipOnlyBatch??${params}`
        }
      }
    }),
    cancelBatch: builder.mutation<void, CancelBatchParams>({
      invalidatesTags: [Tags.CommunicationBatches, Tags.CommunicationInstances],
      query: ({ batch }) => {
        return {
          data: batch,
          method: 'POST',
          url: `/batch/${batch.id}/cancel`
        }
      }
    })
  })
})

export const {
  useGetBatchesQuery,
  useGetCommunicationsQuery,
  useGetAllCommunicationsQuery,
  useGetAllCommunicationTemplatesQuery,
  useCreateCommunicationMutation,
  useGetInstancesQuery,
  useGetMostRecentInstanceEventQuery,
  useGetCommunicationQuery,
  useGetTemplateQuery,
  useUpdateCommunicationMutation,
  useCreateBatchCommunicationInstanceExportMutation,
  useCancelBatchMutation
} = communicationsTwoApi
